/* eslint-disable eqeqeq */
/* eslint-disable max-lines-per-function */
import { Grid, IconButton, Typography, Box, styled, BoxProps, useTheme } from '@mui/material';
import { FeatureBuilding, FeatureBuildingAddress } from '@types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { ICON_LIGHT } from '@constants';

import {
  draggable,
  dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import invariant from 'tiny-invariant';

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import BuildingAddress from './BuildingAddress';

interface BuildingProps {
  identifier: number;
  addresses: FeatureBuildingAddress[];
  index: number;
  expandedId?: number | null;
  setExpandedId: (id: number | null) => void;
  scrollContainer: React.MutableRefObject<HTMLElement | null>;
  setBuildingsAttachedToFeature: Dispatch<SetStateAction<FeatureBuilding[] | null>>;
  setHoveredId: (id: number | null) => void;
}

interface CustomCardProps extends BoxProps {
  isExpanded: boolean;
}

export const CustomCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded'
})<CustomCardProps>(({ isExpanded }) => ({
  backgroundColor: isExpanded ? '#F2F2F2' : 'white',
  '&:hover': {
    backgroundColor: '#F2F2F2'
  },
  border: `1px solid #EAEBF0`,
  borderRadius: '4px',
  paddingBottom: 10,
  paddingTop: 10
}));

interface IdleState {
  type: 'idle';
  closestEdge: Edge | null;
}

interface IsOverState {
  type: 'is-over';
  closestEdge: Edge | null;
}

type ItemState = IdleState | IsOverState;

const idleState: ItemState = { type: 'idle', closestEdge: null };

const Building = ({
  identifier,
  addresses,
  index,
  expandedId,
  setExpandedId,
  setHoveredId,
  scrollContainer,
  setBuildingsAttachedToFeature
}: BuildingProps) => {
  const isExpanded = expandedId === identifier;
  const theme = useTheme();
  const ref = useRef(null);
  const [isDragging, setDragging] = useState<boolean>(false);
  const [state, setState] = useState<ItemState>(idleState);

  useEffect(() => {
    const el = ref.current;
    const scrollContainerElement = scrollContainer.current;

    invariant(el);
    invariant(scrollContainerElement);

    return combine(
      dropTargetForElements({
        element: el,
        getIsSticky: () => true,
        canDrop: ({ source }) => source.data.type === 'building',
        getData: ({ input, element }) => {
          // To attach card data to a drop target
          const data = { type: 'building', identifier, index };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['bottom']
          });
        },
        onDrop: (args) => {
          const { source, self } = args;

          setBuildingsAttachedToFeature((buildingsAttachedToFeature) => {
            const items = [...(buildingsAttachedToFeature as FeatureBuilding[])];
            const [reorderedItem] = items.splice(Number(source.data.index), 1);
            items.splice(Number(self.data.index), 0, reorderedItem);
            return items;
          });
          setState(idleState);
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          setState((current) => {
            if (current.type === 'is-over' && current.closestEdge === closestEdge) {
              return current;
            }
            return { type: 'is-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idleState);
        }
      }),
      draggable({
        element: el,
        onDrag: () => {
          setHoveredId(identifier);
        },
        onDragStart: (args) => {
          setDragging(true);
          if (args.source.data.type === 'building') {
            setExpandedId(null);
          }
        },
        getInitialData: () => {
          return {
            identifier,
            type: 'building',
            index
          };
        },
        onDrop: () => setDragging(false),
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          setCustomNativeDragPreview({
            getOffset: pointerOutsideOfPreview({ x: '0px', y: '8px' }),
            render: ({ container }) => {
              const root = createRoot(container);
              root.render(
                <CustomCard
                  isExpanded
                  sx={{
                    width: 300
                  }}
                >
                  <Grid
                    container
                    justifyContent='space-between'
                    alignItems='center'
                    flexDirection='row'
                    flexWrap='nowrap'
                    px={2}
                  >
                    <Grid container item flexDirection='row' gap={1}>
                      <Grid item display='flex' alignItems='center'>
                        <ApartmentRoundedIcon
                          sx={{
                            color: ICON_LIGHT,
                            fontSize: '24px'
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: theme.palette.neutral[900]
                          }}
                        >
                          {identifier}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: theme.palette.neutral[600]
                          }}
                        >
                          {addresses.length} adressen
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          setExpandedId(isExpanded ? null : identifier);
                        }}
                      >
                        <ExpandMore />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CustomCard>
              );
              return () => {
                root.unmount();
              };
            },
            nativeSetDragImage
          });
        }
      }),
      autoScrollForElements({
        canScroll: () => true,
        element: scrollContainerElement
      })
    );
  }, [
    addresses.length,
    identifier,
    index,
    isExpanded,
    scrollContainer,
    setBuildingsAttachedToFeature,
    setExpandedId,
    setHoveredId,
    theme.palette.neutral
  ]);

  return (
    <>
      <Grid
        onMouseOver={() => {
          setHoveredId(identifier);
        }}
        onMouseLeave={() => {
          setHoveredId(null);
        }}
        ref={ref}
        sx={{
          position: 'relative',
          cursor: 'grab',
          ...(isDragging && {
            opacity: 0.5,
            cursor: 'move'
          }),
          ...(state?.closestEdge === 'bottom' && {
            ':before': {
              content: '""',
              position: 'absolute',
              top: 58,
              left: -8,
              right: 0,
              borderRadius: '50%',
              height: 8,
              width: 8,
              border: `2px solid ${theme.palette.primary.main}`
            },
            ':after': {
              content: '""',
              position: 'absolute',
              bottom: -6,
              left: 0,
              right: 0,
              borderBottom: `2px solid ${theme.palette.primary.main}`
            }
          })
        }}
      >
        <CustomCard isExpanded={isExpanded || isDragging}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            flexDirection='row'
            flexWrap='nowrap'
            px={2}
          >
            <Grid container item flexDirection='row' gap={1}>
              <Grid item display='flex' alignItems='center'>
                <ApartmentRoundedIcon
                  sx={{
                    color: ICON_LIGHT,
                    fontSize: '24px'
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: theme.palette.neutral[900]
                  }}
                >
                  {identifier}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    color: theme.palette.neutral[600]
                  }}
                >
                  {addresses.length} adressen
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setExpandedId(isExpanded ? null : identifier);
                }}
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          </Grid>
        </CustomCard>
      </Grid>
      {isExpanded ? (
        <Grid
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: 'column'
          }}
          id='building-addresses'
        >
          {addresses.map((address, addressIndex) => (
            <BuildingAddress
              setBuildingsAttachedToFeature={setBuildingsAttachedToFeature}
              key={address.id}
              buildingIdentifier={identifier}
              address={address}
              index={addressIndex}
            />
          ))}
        </Grid>
      ) : null}
    </>
  );
};

export default Building;
